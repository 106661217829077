$primaryColor: #9e292b;
$primaryColorOverlay: rgba(158, 41, 43, 0.12);
$secondaryColor: #3a424b;
$accentColor: #e98404;
$grayDarkColor: #3a424b;

$logoGradient: linear-gradient(35deg, #9e292b 35%, #f6a800);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") no-repeat center;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg")
	no-repeat center;
$logoBackgroundMobile: url("/images/logo/logo-dbildungsMobile.svg") no-repeat
	center;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar {
	.sc-branding {
		padding: 0;
		.cloud-logo {
			width: 100%;
			margin: 0;
			padding: 30px;
		}
		.sc-title {
			display: none;
		}
	}

	@media (max-width: 991px) {
		.sc-branding {
			padding: 0;
			.cloud-logo {
				background: $logoBackgroundTablet !important;
				height: 32px;
			}
		}
	}
}

@media (max-width: 575px) {
	.cloud-logo {
		background: $logoBackgroundMobile !important;
	}
}

.primary-color {
	color: $primaryColor;
	display: none;
}
.secondary-color {
	color: $secondaryColor;
	display: none;
}
@import "../lib/bootstrap/scss/bootstrap";

@import "./variables";
@import "./layouts";
@import "./header";
@import "./sections";

body {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	min-height: 100vh;
	min-width: 300px;
}
main {
	margin-top: 2.75rem;
	flex-grow: 1;
}

@include media-breakpoint-down(md) {
	main {
		margin-top: 4.7rem;
	}
}

ul {
	list-style-type: square;
	padding: 0 1.2rem;
}

h1.section-title {
	@include media-breakpoint-down(md) {
		font-size: 36px;
	}
}

video {
	border: 1px solid var(--color-white);
}
